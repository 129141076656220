<template>
  <span>
    <mapbox
      :access-token="accessToken"
      @map-load="onMapLoaded"
      :map-options="{
        style: mapStyle,
        center: center,
        zoom: zoom
      }"
      :geolocate-control="{
        show: true,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: false,
        position: 'top-left'
      }"
    />
    <div
      class="modal fade"
      id="modalProyek"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalProyekTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-custom border-0 rounded-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="close rounded-circle bg-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-xs text-body" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="title-w-line--right p-3">
              <h4
                class="mb-0 text-capitalize text-size-large titilium-font font-weight-normal"
              >
                Status Tahapan {{ modalData.tipe }}
              </h4>
            </div>
            <div class="py-4 px-2">
              <div class="rounded p-3 bg-green-new mb-2">
                <p class="primary-font">Nama Proyek: {{ modalData.nama }}</p>
                <p class="primary-font mb-0">Lokasi: {{ modalData.lokasi }}</p>
              </div>
              <div class="rounded p-3 bg-blue-light-new mb-2">
                <p class="primary-font">Tahapan {{ modalData.tipe }}</p>
                <div class="d-flex flex-wrap">
                  <template v-if="modalData.tipe == 'DED'">
                    <div
                      v-for="(item, key) in listTahapanDED"
                      :key="key"
                      :class="
                        `card-dashboard ${
                          modalData.tahapan != item ? 'border-gray' : ''
                        }  card-dashboard--blue bg-white rounded px-3 py-2 mr-2 mb-2`
                      "
                    >
                      <p class="mb-0">
                        {{ item }}
                      </p>
                    </div>
                  </template>
                  <template v-if="modalData.tipe == 'Konstruksi'">
                    <div
                      v-for="(item, key) in listTahapanKonstruksi"
                      :key="key"
                      :class="
                        `card-dashboard ${
                          modalData.tahapan != item ? 'border-gray' : ''
                        }  card-dashboard--blue bg-white rounded px-3 py-2 mr-2 mb-2`
                      "
                    >
                      <p class="mb-0">
                        {{ item }}
                      </p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="rounded p-3 bg-orange-new mb-2">
                <p class="primary-font">
                  Kurva S
                </p>
                <div class="card-dashboard-content bg-white p-3 rounded">
                  <div class="chart-container">
                    <canvas id="kurva" width="400" height="200"></canvas>
                  </div>
                </div>
              </div>
              <div class="rounded p-3 bg-orange-new mb-2">
                <p class="primary-font">
                  Gallery
                </p>
                <div
                  class="card-dashboard-content p-3 rounded"
                  style="background-color:rgba(195, 195, 195, 0.48)"
                >
                  <div class=" gallery-slider mb-0">
                    <slick
                      ref="slick"
                      :options="slickOptions"
                      v-if="Gallery.length > 0"
                    >
                      <div v-for="(item, index) in Gallery" :key="index">
                        <div class="gallery-item">
                          <a href="#" v-on:click.prevent="imageLoad(Gallery)">
                            <div
                              class="gallery-thumb"
                              :style="`backgroundImage: url('${item.src}')`"
                            ></div>
                          </a>
                        </div>
                      </div>
                    </slick>
                    <LightBox
                      :images="itemImage"
                      v-if="itemImage.length"
                      :show-caption="true"
                    ></LightBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import mapboxgl from 'mapbox-gl';
import Slick from 'vue-slick';
import LightBox from 'vue-image-lightbox';
import geoJsonData from 'json-loader!./datakota.geojson';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import 'slick-carousel/slick/slick.css';
let _map;
var that;
var myChart;

export default {
  components: { Mapbox, Slick, LightBox },
  data() {
    return {
      mapbox: null,
      geoJsonData: geoJsonData,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      center: [107.668887, -7.090911],
      zoom: 7,
      accessToken:
        'pk.eyJ1IjoidmNvbmZpY2tlciIsImEiOiJjazJ5ZGtyMGIwNnliM2NvOGVvZnhoOGMwIn0.-5kuq-J8FTqiYeNAv_qwRA',
      geojson: {},
      chartKurva: {
        type: 'line',
        data: {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des'
          ],
          datasets: [
            {
              label: 'Rencana',
              fill: false,
              borderWidth: 4,
              data: [50, 20, 50, 90, 50, 100, 80, 123, 140, 130, 150, 150, 170],
              lineTension: 0,
              backgroundColor: '#00C318',
              borderColor: '#00C318'
            },
            {
              label: 'Realisasi',
              fill: false,
              borderWidth: 4,
              data: [
                50,
                20,
                50,
                90,
                50,
                100,
                123,
                333,
                233,
                130,
                150,
                123,
                170
              ],
              lineTension: 0,
              backgroundColor: '#EE7363',
              borderColor: '#EE7363'
            },
            {
              label: 'Deviasi',
              fill: false,
              borderWidth: 4,
              data: [
                50,
                20,
                50,
                90,
                50,
                100,
                123,
                333,
                233,
                130,
                150,
                123,
                170
              ],
              lineTension: 0,
              backgroundColor: 'blue',
              borderColor: 'blue'
            }
          ]
        },
        options: {
          bezierCurve: false,
          legend: {
            display: true
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  display: false
                }
              }
            ]
          }
        }
      },
      baseApi: window.links.map_strategis,
      modalData: {},
      Gallery: [],
      listTahapanKonstruksi: [
        'PERENCANAAN',
        'PENGADAAN',
        'MC-0',
        'MC-25',
        'MC-50',
        'MC-75',
        'MC-100',
        'LAUNCHING'
      ],
      listTahapanDED: [
        'INISIASI',
        'SURVEI',
        'PENGADAAN KONSULTAN PERENCANA',
        'KICK-OFF MEETING',
        'LAPORAN AWAL',
        'SCHEMATIC DESIGN',
        'ASISTENSI',
        'LAPORAN ANTARA',
        'DED',
        'LAPORAN AKHIR'
      ],
      slickOptions: {
        //options can be used from the plugin documentation
        slidesToShow: 2,
        infinite: false,
        accessibility: true,
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        speed: 500,
        draggable: true,
        edgeFriction: 0.3,
        swipe: true,
        fade: false,
        prevArrow:
          "<span class='slick-prev'><i class='fa fa-angle-left'></i></span>",
        nextArrow:
          "<span class='slick-next'><i class='fa fa-angle-right'></i></span>"
      },
      itemImage: []
    };
  },
  created() {
    that = this;
    this.$eventHub.$on('kategori', function(kategori, kabkot, year) {
      if (kabkot != 0) {
        _map.easeTo({
          center: [kabkot.longitude, kabkot.latitude],
          zoom: 8
        });
        kabkot = kabkot.id;
      } else {
        _map.easeTo({
          center: [107.668887, -7.090911],
          zoom: that.zoom
        });
        kabkot = 0;
      }
      window.axios
        .get(
          `${window.links.map_strategis}dashboard/map/detail-proyek-geojson-kabkotkat/${kategori}/${kabkot}`,
          {
            params: {
              year: year == '' ? null : year
            }
          }
        )
        .then(response => {
          if (response.status == 200) {
            that.geojson = response.data.gjs;
            _map.getSource('attractions').setData(that.geojson);
          }
        });

      window
        .$('.mapboxgl-popup')
        .removeAttr('style')
        .hide();
    });
  },

  methods: {
    onMapLoaded(map) {
      _map = map;
      for (var icon_name in window.map_icons) {
        if (!Object.prototype.hasOwnProperty.call(window.map_icons, icon_name))
          continue;
        // if (!window.map_icons.hasOwnProperty(icon_name)) continue;
        let a = icon_name;
        map.loadImage(window.map_icons[a], function(error, image) {
          map.addImage(a, image);
        });
      }
      this.init_map(map);
    },

    createInfoWindow(feature) {
      that.Gallery = [];
      that.modalData = feature.properties;
      window.axios
        .get(
          window.links.map_strategis +
            'dashboard/proyek-strategis-persentase/get-persentase',
          {
            params: {
              proyek_strategis_id: feature.properties.id
            }
          }
        )
        .then(response => {
          try {
            myChart.destroy();
          } catch (err) {
            // alert(err);
          }

          that.chartKurva.data.datasets[0].data = response.data.rencana;
          that.chartKurva.data.datasets[1].data = response.data.realisasi;
          that.chartKurva.data.datasets[2].data = response.data.deviasi;
          this.setChart();
        });
      window.axios
        .get(window.links.map_strategis + 'dashboard/gallery/get-data', {
          params: {
            proyek_strategis_id: feature.properties.id
          }
        })
        .then(response => {
          if (response.data.length > 0) {
            that.Gallery = response.data.map(item => {
              return {
                src: item.url_photo,
                thumb: item.url_photo,
                caption: item.keterangan
              };
            });
          }
        });
      var htmldescription = `
          <span class="preview-content" href="${
            feature.properties.url
          }" target="_blank">
            <b>${feature.properties.nama}</b><br>
            <span>${feature.properties.lokasi}</span><br>
            <span>${feature.properties.tipe}</span><br>
            <span>${
              feature.properties.keterangan
                ? 'Tidak ada keterangan'
                : feature.properties.keterangan
            }</span><br>
          </span>`;
      window.$('#modalProyek').modal('show');
      return htmldescription;
    },
    init_map(map) {
      window.axios
        .get(
          `${window.links.map_strategis}dashboard/map/detail-proyek-geojson-kabkotkat/0/0`,
          {
            params: {
              year: null
            }
          }
        )
        .then(response => {
          if (response.status == 200) {
            this.geojson = response.data.gjs;
            this.loadMap(map);
          }
        });
    },
    loadMap(map, status = false) {
      // COLOR WILAYAH
      if (!status) {
        map.addSource('color-region', {
          type: 'geojson',
          data: this.geoJsonData
        });
        for (let i = 0; i < this.geoJsonData.features.length; i++) {
          const element = this.geoJsonData.features[i];
          let color = this.geoJsonData.features[i].properties.color;
          map.addLayer({
            id: 'lokasi' + i,
            type: 'fill',
            source: {
              type: 'geojson',
              data: element
            },
            paint: {
              'fill-color': color,
              'fill-outline-color': 'rgba(10, 10, 24, 1)',
              'fill-opacity': 0.75
            }
          });
        }
      }

      map.addSource('attractions', {
        type: 'geojson',
        data: this.geojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });
      map.addLayer({
        id: 'clusters',
        type: 'symbol',
        source: 'attractions',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': [
            'step',
            ['get', 'point_count'],
            'marker_radius_small',
            100,
            'marker_radius_medium',
            750,
            'marker_radius_large'
          ],
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'attractions',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Roboto Bold'],
          'text-size': 14,
          'text-offset': [0, 0.1]
        },
        paint: {
          'text-color': '#000'
        }
      });

      map.addLayer({
        id: 'places',
        type: 'symbol',
        source: 'attractions',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': '{icon}',
          'icon-size': 0.3,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          visibility: 'visible'
        }
      });
      map.on('click', 'clusters', function(e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        var clusterId = features[0].properties.cluster_id;
        map
          .getSource('attractions')
          .getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on('mouseenter', 'places', function() {
        map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'places', function() {
        map.getCanvas().style.cursor = '';
      });

      map.on('click', 'places', function(e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        var html = that.createInfoWindow(e.features[0]);
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // let popup = new mapboxgl.Popup()
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(html)
          .addTo(map);
      });
    },
    setChart() {
      var ctx = document.getElementById('kurva');
      let chartData = {
        labels: this.chartKurva.data.labels,
        datasets: this.chartKurva.data.datasets
      };
      myChart = new window.Chart(ctx, {
        type: this.chartKurva.type,
        data: chartData,
        options: this.chartKurva.options
      });
    },
    imageLoad(img) {
      this.itemImage = [];
      setTimeout(() => {
        this.itemImage = img;
      }, 500);
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style>
#map {
  height: 300px;
}
.mapboxgl-popup-close-button {
  display: none;
}
</style>

<style>
#modalProyek .gallery-thumb {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100% !important;
  padding-bottom: 100%;
  border-radius: 10px;
}
#modalProyek .gallery-thumb-not-found {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100% !important;
  padding-bottom: 50%;
  padding-top: -2%;
  margin-top: -5%;
  border-radius: 10px;
}
#modalProyek .gallery-item {
  padding: 0 10px;
}
#modalProyek .gallery-slider {
  padding: 0px 25px 25px;
  margin: 0 -10px;
  margin-top: 12px;
  margin-bottom: 10px;
}
#modalProyek .gallery-slider .slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  background: #fff !important;
  padding: 0px;
  border-radius: 50%;
  z-index: 1;
  width: 25px;
  line-height: 25px;
  text-align: center;
}
#modalProyek .gallery-slider .slick-next {
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fff !important;
  padding: 0px;
  border-radius: 50%;
  z-index: 1;
  width: 25px;
  line-height: 25px;
  text-align: center;
}
#modalProyek .gallery-slider .slick-prev:before,
.gallery-slider .slick-next:before {
  font-size: 15px;
}
#modalProyek .slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li button {
  display: none;
}
.slick-dots li:after {
  display: block;
  height: 13px;
  width: 13px;
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s;
  opacity: 0;
  background-color: white;
}

.slick-dots li.slick-active:after {
  opacity: 1;
}
#modalProyek .slick-dots li {
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  position: relative;
  outline: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  opacity: 1;
  border-radius: 50%;
  line-height: 0;
  box-shadow: inset 0 0 0 2px #c0c0c0;
  transition: all 0.3s;
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 0 2px #ffffff;
}
#modalProyek .slick-dots li:after {
  height: 9px;
  width: 9px;
}
</style>
