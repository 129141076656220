<template>
  <section class="section section-standalone" id="proyek-strategis">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="primary-font text-size-title text-uppercase mb-0">
            Proyek Strategis
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="tahun"
              v-on:change="kategoriChange()"
              style="width:auto;min-width:80px"
            >
              <option value="">Tahun</option>
              <option :value="item" v-for="item in array_tahun" :key="item">
                {{ item }}
              </option>
            </select>
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              style="width:auto;min-width:266px"
              v-model="kategori"
              v-on:change="kategoriChange()"
            >
              <option value="0">Kategori</option>
              <option
                :value="item.id"
                v-for="item in listKategori"
                :key="item.id"
                >{{ item.nama }}</option
              >
            </select>
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="kabkot"
              v-on:change="kategoriChange()"
            >
              <option value="0">Kabupaten / Kota</option>
              <option :value="item" v-for="item in listKabkot" :key="item.id">{{
                item.nama
              }}</option>
            </select>
            <!-- <select class="form-control form-control-sm form-control-custom" style="width: 160px">
                        <option>Kota/Kabupaten</option>
                    </select> -->
          </div>
        </div>
        <div class="grid-section">
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Lokasi
                </h4>
              </div>
              <div class="card-dashboard-content">
                <!-- <img src="@/assets/img/maps.png" alt="" class="img-responsive"
                                style="height: 100%;object-fit: cover;"> -->
                <Map width="100%" v-if="maps" />
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Status Proyek Konstruksi
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="chart-doughnut--wrapper">
                      <canvas
                        id="status-proyek"
                        width="400"
                        :chart="chartStatus"
                      ></canvas>
                      <div class="chart-doughnut--text">
                        <h3
                          class="mb-1 secondary-font text-size-large font-weight-bold"
                        >
                          {{ status.total }}
                        </h3>
                        <h6
                          class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                        >
                          Status
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card bg-blue-new p-2 mb-2">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="flex-row">
                          <img
                            src="@/assets/img/icon-strategi/strategi-icon-1.svg"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="flex-row text-right">
                          <h4
                            class="font-weight-bold text-size-large mb-1 text-blue-new d-flex align-items-center justify-content-end"
                          >
                            {{ status.aman }}
                          </h4>
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            AMAN
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card bg-yellow-new p-2 mb-2">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="flex-row">
                          <img
                            src="@/assets/img/icon-strategi/strategi-icon-2.svg"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="flex-row text-right">
                          <h4
                            class="font-weight-bold text-size-large mb-1 text-yellow-new d-flex align-items-center justify-content-end"
                          >
                            {{ status.siaga }}
                          </h4>
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            SIAGA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card bg-red-new p-2 mb-2">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="flex-row">
                          <img
                            src="@/assets/img/icon-strategi/strategi-icon-3.svg"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="flex-row text-right">
                          <h4
                            class="font-weight-bold text-size-large mb-1 text-red-new d-flex align-items-center justify-content-end"
                          >
                            {{ status.awas }}
                          </h4>
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            AWAS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Jumlah Konstruksi
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="chart-doughnut--wrapper">
                      <canvas
                        id="total-project"
                        width="400"
                        :chart="chartProject"
                      ></canvas>
                      <div class="chart-doughnut--text">
                        <h3
                          class="mb-1 secondary-font text-size-large font-weight-bold"
                        >
                          {{ konstruksi.total }}
                        </h3>
                        <h6
                          class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                        >
                          Total Project
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" style="overflow-y:scroll;height:250px">
                    <div
                      class="card bg-green-new p-2 mb-2"
                      :style="`background-color:${backgroundColor[key]}`"
                      v-for="(item, key) in konstruksi.proyek"
                      :key="key"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="flex-row">
                          <img
                            src="@/assets/img/icon-strategi/building.png"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="flex-row text-right">
                          <h4
                            class="font-weight-bold text-size-large mb-1 d-flex align-items-center justify-content-end"
                          >
                            {{ item.total }}
                          </h4>
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            {{ item.nama }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Jumlah DED
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="chart-doughnut--wrapper">
                      <canvas
                        id="total-ded"
                        width="400"
                        :chart="chartDED"
                      ></canvas>
                      <div class="chart-doughnut--text">
                        <h3
                          class="mb-1 secondary-font text-size-large font-weight-bold"
                        >
                          {{ DED.total }}
                        </h3>
                        <h6
                          class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                        >
                          Total DED
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" style="overflow-y:scroll;height:250px">
                    <div
                      class="card bg-green-new p-2 mb-2"
                      :style="`background-color:${item.color}`"
                      v-for="(item, key) in DED.proyek"
                      :key="key"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="flex-row">
                          <img
                            src="@/assets/img/icon-strategi/building.png"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="flex-row text-right">
                          <h4
                            class="font-weight-bold text-size-large mb-1 d-flex align-items-center justify-content-end"
                          >
                            {{ item.total }}
                          </h4>
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            {{ item.nama }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3" style="margin-right: -15px;">
          <div class="bg-white rounded border h-100 border-radius-style">
            <div class="card-dashboard-title border-bottom px-3 py-2">
              <h4
                class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
              >
                Jumlah DED
              </h4>
            </div>
            <div class="card-dashboard-content p-3">
              <div
                class="c-dashboard-title d-flex justify-content-between pl-2"
              >
                <h1
                  class="primary-font text-size-title text-uppercase mb-0"
                ></h1>
                <div class="c-dashboard-title-option d-flex align-items-center">
                  <b-form-select
                    v-model="filterStatus"
                    :options="[
                      'Status',
                      'Tahap Usulan',
                      'Proses Perencanaan',
                      'Selesai'
                    ]"
                    size="sm"
                  ></b-form-select>
                </div>
              </div>
              <b-table
                :items="getItems"
                :fields="columns"
                :current-page="currentPage"
                :per-page="perPage"
                striped
                hover
                responsive
                head-variant="dark"
              ></b-table>
              <b-pagination
                :total-rows="getItems.length"
                :per-page="perPage"
                v-model="currentPage"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br>
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BTable, BPagination, BFormSelect } from 'bootstrap-vue';
import Map from '@/components/MapStrategis.vue';
import dataProyek from './proyek.json';
// let date = new Date(window.date_now);
var MyStatus;
var MyStatus1;
var MyStatus2;
export default {
  metaInfo() {
    return {
      title: 'Proyek Strategis'
    };
  },
  components: {
    Map,
    BTable,
    BPagination,
    BFormSelect
  },
  data() {
    return {
      tahun: '',
      filterStatus: 'Status',
      maps: false,
      array_tahun: window.date_years,
      sumberTooltip: false,
      sumber_data: null,
      backgroundColor: [
        '#FFC650',
        '#FD8F30',
        '#F4776E',
        '#01A7E5',
        '#17C12B',
        '#9BC065',
        '#98908C',
        '#01A7E5',
        '#FDC730',
        '#EB5352'
      ],
      chartStatus: {
        datasets: [
          {
            data: [],
            backgroundColor: ['#01A7E5', '#FDC730', '#EB5352'],
            borderWidth: 0
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      chartProject: {
        datasets: [
          {
            data: [4, 2, 3, 4, 5, 6, 7],
            backgroundColor: [
              '#FFC650',
              '#FD8F30',
              '#F4776E',
              '#01A7E5',
              '#17C12B',
              '#9BC065',
              '#98908C',
              '#01A7E5',
              '#FDC730',
              '#EB5352'
            ],
            borderWidth: 0
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      chartDED: {
        datasets: [
          {
            data: [128, 15, 101],
            backgroundColor: [
              '#46A6DF',
              '#F6C851',
              '#DB5D58',
              '#01A7E5',
              '#17C12B',
              '#9BC065',
              '#98908C',
              '#01A7E5',
              '#FDC730',
              '#EB5352'
            ],
            borderWidth: 0
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      status: {
        aman: 0,
        siaga: 0,
        awas: 0,
        total: 0
      },
      konstruksi: {
        proyek: [],
        total: 0
      },
      DED: {
        proyek: [
          { nama: 'Tahap Usulan', total: 101, color: '#F4776E' },
          { nama: 'Dalam Proses', total: 15, color: '#FFC650' },
          { nama: 'Selesai', total: 128, color: '#01A7E5' }
        ],
        total: 244
      },
      geojson: {},
      kategori: 0,
      kabkot: 0,
      listKategori: [],
      listKabkot: [],
      currentPage: 1,
      perPage: 20,
      columns: [
        {
          key: 'no',
          label: 'No',
          sortable: true
        },
        {
          key: 'nama_proyek',
          label: 'NAMA PROYEK',
          sortable: true
        },
        {
          key: 'lokasi',
          label: 'LOKASI',
          sortable: true
        },
        {
          key: 'tahapan',
          label: 'TAHAPAN',
          sortable: true
        },
        {
          key: 'status',
          label: 'STATUS',
          sortable: true
        },
        {
          key: 'tahun',
          label: 'TAHUN',
          sortable: true
        }
      ],
      tableData: dataProyek,
      tableOptions: {
        pagination: true,
        pageSize: 20,
        theadClasses: 'thead-dark',
        paginationParts: ['pageList']
      }
    };
  },
  mounted() {
    this.loadChart();
    // this.loadDED();
    this.loadKonstruksi();
    this.loadStatus();
    setTimeout(() => {
      this.maps = true;
      this.setChart();
    }, 1000);
  },
  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    async loadStatus() {
      await window.axios
        .get(
          window.links.map_strategis +
            'dashboard/proyek-strategis/get-status-proyek?tipe=Konstruksi',
          {
            params: {
              year: this.tahun,
              kabupaten_kota_id: this.kabkot == 0 ? 0 : this.kabkot.id,
              proyek_kategori_id: this.kategori
            }
          }
        )
        .then(response => {
          this.chartStatus.datasets[0].data = [
            parseInt(response.data[0]),
            parseInt(response.data[1]),
            parseInt(response.data[2])
          ];
          this.status.aman = response.data[0];
          this.status.siaga = response.data[1];
          this.status.awas = response.data[2];
          this.status.total = this.sumArray([
            this.status.aman,
            this.status.siaga,
            this.status.awas
          ]);
        });
    },
    async loadChart() {
      await window.axios
        .post(window.base_api + 'page/proyek-strategis')
        .then(response => {
          this.sumber_data = response.data.sumber_data;
          this.listKategori = response.data.kategori;
          this.listKabkot = response.data.kabkot;
        });
    },
    async loadDED() {
      await window.axios
        .get(
          window.links.map_strategis +
            'dashboard/proyek-strategis/get-jumlah-proyek',
          {
            params: {
              tipe: 'DED',
              year: this.tahun,
              kabupaten_kota_id: this.kabkot == 0 ? 0 : this.kabkot.id
            }
          }
        )
        .then(response => {
          this.DED.proyek = response.data.proyek;
          if (this.DED.proyek.length > 0) {
            this.DED.proyek = this.DED.proyek.sort(this.compare);
          }
          this.DED.total = response.data.total;
          var data = this.DED.proyek.map(item => {
            return item.total;
          });
          this.chartDED.datasets[0].data = this.DED.total > 0 ? data : [];
        });
    },
    async loadKonstruksi() {
      await window.axios
        .get(
          window.links.map_strategis +
            'dashboard/proyek-strategis/get-jumlah-proyek',
          {
            params: {
              tipe: 'Konstruksi',
              year: this.tahun,
              kabupaten_kota_id: this.kabkot == 0 ? 0 : this.kabkot.id
            }
          }
        )
        .then(response => {
          this.konstruksi.proyek = response.data.proyek;
          if (this.konstruksi.proyek.length > 0) {
            this.konstruksi.proyek = this.konstruksi.proyek.sort(this.compare);
          }
          this.konstruksi.total = response.data.total;
          var data = this.konstruksi.proyek.map(item => {
            return item.total;
          });

          this.chartProject.datasets[0].data =
            this.konstruksi.total > 0 ? data : [];
        });
    },
    setChart() {
      var ctx = document.getElementById('status-proyek').getContext('2d');
      let chartData = {
        datasets: this.chartStatus.datasets
      };

      MyStatus = new window.Chart(ctx, {
        type: this.chartStatus.type,
        data: chartData,
        options: this.chartStatus.options
      });

      ctx = document.getElementById('total-project').getContext('2d');
      chartData = {
        datasets: this.chartProject.datasets
      };

      MyStatus1 = new window.Chart(ctx, {
        type: this.chartProject.type,
        data: chartData,
        options: this.chartProject.options
      });

      ctx = document.getElementById('total-ded').getContext('2d');
      chartData = {
        datasets: this.chartDED.datasets
      };

      MyStatus2 = new window.Chart(ctx, {
        type: this.chartDED.type,
        data: chartData,
        options: this.chartDED.options
      });
    },
    sumArray(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    kategoriChange() {
      MyStatus.destroy();
      MyStatus1.destroy();
      MyStatus2.destroy();
      this.loadDED();
      this.loadKonstruksi();
      this.loadStatus();
      setTimeout(() => {
        this.setChart();
      }, 1000);

      this.$eventHub.$emit('kategori', this.kategori, this.kabkot, this.tahun);
    },
    compare(a, b) {
      a = a.total;
      b = b.total;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    }
  },
  computed: {
    getItems() {
      if (this.filterStatus && this.filterStatus !== 'Status') {
        return this.tableData.filter(item => item.status === this.filterStatus);
      } else {
        return this.tableData;
      }
    }
  }
};
</script>

<style></style>
